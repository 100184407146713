import React from 'react'
import { SectionWrapper } from '../../components'
import { Expandable } from '../../../../components/expandable'
import styled from 'styled-components'

const SList = styled.ul`
    list-style: disc;
    padding-left: 20px;
    margin: 10px 0;
`

const SLink = styled.a`
    color: ${({ theme }) => theme.colors.link};
    text-decoration: underline;
`

const SHeader = styled.p`
    font-weight: bold;
    margin: 30px 0 10px;
`

const SImg = styled.img`
    width: 100%;
`

export const StenogramPrevewSection = () => {
    return (
        <SectionWrapper>
            <Expandable
                collapsedText={'Kliknij i zobacz jak wygląda stenogram'}
                expandedText={'Ukryj podgląd'}
            >
                Do każdego nagrania otrzymasz osobny plik PDF ze stenogramem.
                Plik będzie zawierał:
                <SList>
                    <li>Datę wykonania i tytuł nagrania</li>
                    <li>Podział na mówców w formie anonimowej</li>
                    <li>Przedział czasowy co 2 minuty</li>
                    <li>Opis dźwięków i emocji</li>
                    <li>Podpis pracownika i pieczęć naszej firmy</li>
                </SList>
                <SLink
                    href="https://www.protranskrypcje.pl/blog/jak-wyglada-stenogram-do-sadu"
                    target="_blank"
                >
                    Kliknij tutaj by przeczytać więcej szczegółów
                </SLink>
                <SHeader>Przykładowy stenogram:</SHeader>
                <SImg src="/assets/stenogram.png" />
            </Expandable>
        </SectionWrapper>
    )
}
