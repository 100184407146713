import React, { useEffect, useRef, useState } from 'react'
import { Popup } from '../../../../components'
import ReCAPTCHA from 'react-google-recaptcha'
import { useWizard } from '../../../../context'
import styled from 'styled-components'

const SText = styled.p`
    font-weight: 600;
    padding-bottom: 16px;
    text-align: center;
    font-size: 15px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 16px;
    }
`

const SPopup = styled(Popup)`
    padding: 15px 24px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        padding: 32px 56px;
        max-width: unset;
    }
`

const SCompactCaptchaContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        display: none;
    }
`

const SCaptchaContainer = styled.div`
    display: none;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        display: block;
    }
`

export const Captcha = () => {
    const captchaRef = useRef(null)
    const { captchaSolved, setCaptchaSolved, createClientSession } = useWizard()

    const handleCaptchaChange = async () => {
        setCaptchaSolved(true)
        await createClientSession()
    }

    const onLoaded = () => {
        try {
            if (window?.grecaptcha?.reset)
                window.grecaptcha.reset(captchaRef.current)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <SPopup visuallyHidden={captchaSolved}>
            <SText>Aby kontynuować, rozwiąż reCAPTCHA</SText>
            <SCompactCaptchaContainer>
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    size={'compact'}
                    asyncScriptOnLoad={onLoaded}
                    onChange={handleCaptchaChange}
                />
            </SCompactCaptchaContainer>
            <SCaptchaContainer>
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    size={'normal'}
                    asyncScriptOnLoad={onLoaded}
                    onChange={handleCaptchaChange}
                />
            </SCaptchaContainer>
        </SPopup>
    )
}
