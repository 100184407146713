import React, { useState, useEffect } from 'react'
import { Layout } from '../../layout'
import { ErrorMessage } from './components'
import styled from 'styled-components'
import { ENDPOINTS, axiosInstance } from '../../api'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { theme } from '../../theme'

const SWrapper = styled.div`
    min-height: calc(100vh - 380px);
    display: flex;
    align-items; center;
    justify-content: center;

     @media screen and (max-width: ${({ theme: { breakpoints } }) =>
         breakpoints.md}) {
         min-height: calc(100vh - 500px);
    }
`

export const Error = ({ orderId }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        try {
            setLoading(true)

            const { data } = await axiosInstance.get(
                ENDPOINTS.CLIENT_ORDER_DETAILS(orderId)
            )

            if (data.errorCode > 300) throw new Error()
            setData(data)
        } catch {
            setData(null)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Layout>
            <SWrapper>
                {loading ? (
                    <ScaleLoader
                        color={theme.colors.secondary}
                        width={6}
                        margin={3}
                        radius={4}
                        height={60}
                    />
                ) : (
                    <ErrorMessage
                        orderId={orderId}
                        orderNumber={data?.orderNumber}
                    />
                )}
            </SWrapper>
        </Layout>
    )
}
