import { useEffect, useState } from 'react'
import { ENDPOINTS, axiosInstance } from '../api'
import { getExtension } from '../utils/get-extension'
import { ERROR_MAP } from '../constants'

export const useQueue = ({ files, captchaSolved, updateFile }) => {
    const [clientSessionId, setClientSessionId] = useState(null)

    const createClientSession = async () => {
        if (!clientSessionId) {
            const { data } = await axiosInstance.get(ENDPOINTS.CREATE_SESSION)
            setClientSessionId(data.clientId)
        }
    }

    const uploadFileAsync = async (file) => {
        if (!captchaSolved || !clientSessionId) return

        updateFile({
            ...file,
            progress: 1,
        })

        try {
            const formData = new FormData()
            formData.append('videoFile', file.file)
            formData.append('size', file.file.size)
            formData.append('length', Math.ceil(file.duration / 60))
            formData.append('format', getExtension(file.file))
            formData.append('clientId', clientSessionId)

            const { data } = await axiosInstance.post(
                ENDPOINTS.UPLOAD,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: ({ loaded, total, ...rest }) => {
                        const percentageUploaded = Math.floor(
                            (loaded / total) * 100
                        )

                        const progress = Math.min(percentageUploaded, 99)

                        updateFile({ tempId: file.tempId, progress })
                    },
                }
            )

            updateFile({
                ...file,
                uploaded: true,
                progress: 100,
                fileId: data.fileId,
            })
        } catch (err) {
            updateFile({
                ...file,
                progress: 0,
                error:
                    ERROR_MAP[err?.errorStatus] ||
                    'Wgrywanie pliku nie powiodło się.',
            })
        }
    }

    const handleNewFiles = async () => {
        const newFiles = files.filter((file) => {
            const durationCorrect = !!file.duration

            const fileReadyForUpload =
                file.analysisReady && durationCorrect && !file.error

            const fileAlreadyUploaded = file.uploaded || file.progress > 0

            return fileReadyForUpload && !fileAlreadyUploaded
        })

        if (newFiles.length > 0) {
            await Promise.all(newFiles.map(uploadFileAsync))
        }
    }

    useEffect(() => {
        handleNewFiles()
    }, [files])

    return {
        createClientSession,
        clientSessionId,
    }
}
