import React, { useState } from 'react'
import { Button } from '../button'
import { BUTTON_VARIANTS } from '../button/style.js'
import styled from 'styled-components'
import { ArrowRightIcon } from '../../icons/index.js'

const SButton = styled(Button)`
    width: 100%;

 @media screen and (max-width: ${({ theme: { breakpoints } }) =>
     breakpoints.md}) {
        font-size: 14px;

`

const SIcon = styled(ArrowRightIcon)`
    height: 23px;
    width: 23px;
    transform: rotate(${({ expand }) => (expand ? -90 : 90)}deg);
    transition: transform 0.3s linear;
    flex-shrink: 0;
`

const SExandable = styled.div`
    padding: 20px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 14px;
    }
`

export const Expandable = ({ children, collapsedText, expandedText }) => {
    const [expand, setExpand] = useState(false)

    return (
        <div>
            <SButton
                variant={BUTTON_VARIANTS.secondary}
                onClick={() => setExpand(!expand)}
            >
                {expand ? expandedText : collapsedText}
                <SIcon expand={expand} />
            </SButton>
            {expand && <SExandable>{children}</SExandable>}
        </div>
    )
}
