import React from 'react'
import { Button } from '../'
import { BUTTON_VARIANTS } from '../button/style'
import { AddIcon, AddIconWhite } from '../../icons'
import { useHover } from '../../hooks'
import styled from 'styled-components'

const SButton = styled(Button)`
    position: relative;

    &:hover,
    &:focus,
    &:active {
        svg:last-child {
            opacity: 0;
        }
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`

const SIconContainer = styled.div`
    position: relative;
    height: 20px;
`

const SAddIcon = styled(AddIcon)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 0.3s linear;
`

const SAddIconWhite = styled(AddIconWhite)`
    width: 20px;
    height: 20px;
`

export const UploadButton = (props) => {
    const { hovered, eventHandlers } = useHover()

    return (
        <SButton
            variant={BUTTON_VARIANTS.outline}
            {...eventHandlers}
            {...props}
        >
            <SIconContainer>
                <SAddIconWhite />
                <SAddIcon />
            </SIconContainer>
            Dodaj pliki
        </SButton>
    )
}
