import { COMPLETION_TIME } from '../constants'

export const EXPRESS_PRICING = {
    slot_1: 249,
    slot_2: 449,
    slot_3: 599,
    slot_4: 699,
    slot_5: 849,
    slot_6: 1129,
    slot_7: 1399,
    slot_8: 1679,
    per_minute_1: 13.29,
}

export const STANDARD_PRICING = {
    slot_1: 199,
    slot_2: 349,
    slot_3: 499,
    slot_4: 599,
    slot_5: 749,
    slot_6: 999,
    slot_7: 1249,
    slot_8: 1499,
    per_minute_1: 11.87,
    per_minute_2: 11.24,
    per_minute_3: 10.62,
    per_minute_4: 9.99,
}

const PRICING = {
    [COMPLETION_TIME.express]: EXPRESS_PRICING,
    [COMPLETION_TIME.standard]: STANDARD_PRICING,
}

export const EXPRESS_MODE_AVAILABLE_THRESHOLD = 240

export const calculatePrice = (minutes, mode) => {
    const pricing = PRICING[mode]

    switch (true) {
        case minutes === 0:
            return 0
        case minutes <= 10:
            return pricing.slot_1
        case minutes <= 20:
            return pricing.slot_2
        case minutes <= 30:
            return pricing.slot_3
        case minutes <= 45:
            return pricing.slot_4
        case minutes <= 60:
            return pricing.slot_5
        case minutes <= 80:
            return pricing.slot_6
        case minutes <= 100:
            return pricing.slot_7
        case minutes <= 120:
            return pricing.slot_8
        case minutes <= 240:
            return pricing.slot_8 + (minutes - 120) * pricing.per_minute_1
        case minutes <= 360:
            return pricing.slot_8 + (minutes - 120) * pricing.per_minute_2
        case minutes <= 600:
            return pricing.slot_8 + (minutes - 120) * pricing.per_minute_3
        default:
            return pricing.slot_8 + (minutes - 120) * pricing.per_minute_4
    }
}

export const getCompletionTime = (minutes, mode) => {
    if (mode === COMPLETION_TIME.standard) return '10 dni'

    switch (true) {
        case minutes <= 60:
            return '48h'
        case minutes <= 120:
            return '72h'
        case minutes <= 240:
            return '96h'
        default:
            return 'niedostępny'
    }
}
