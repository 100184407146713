import React, { useEffect, useMemo } from 'react'
import { SectionWrapper } from '../../components'
import { Controller, useFormContext } from 'react-hook-form'
import {
    FORM_FIELDS,
    COMPLETION_TIME,
    COMPLETION_TIME_LABEL,
} from '../../../../constants'
import { RadioButton } from '../../../../components'
import styled from 'styled-components'
import { useWizard } from '../../../../context'
import {
    getCompletionTime,
    EXPRESS_MODE_AVAILABLE_THRESHOLD,
} from '../../../../utils/calculate-price'

const SOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-direction: row;
        gap: 20px;
    }
`

export const CompletionTimeSelect = () => {
    const { control, watch, setValue } = useFormContext()
    const { totalDuration } = useWizard()

    const selectedMode = watch(FORM_FIELDS.completionTime)
    const disableExpress = useMemo(
        () => totalDuration > EXPRESS_MODE_AVAILABLE_THRESHOLD,
        [totalDuration]
    )

    useEffect(() => {
        if (selectedMode === COMPLETION_TIME.express && disableExpress) {
            setValue(FORM_FIELDS.completionTime, COMPLETION_TIME.standard)
        }
    }, [totalDuration, disableExpress, selectedMode])

    return (
        <SectionWrapper title="Wybierz czas realizacji">
            <Controller
                control={control}
                name={FORM_FIELDS.completionTime}
                render={({ field: { onChange, value } }) => {
                    return (
                        <SOptionsWrapper>
                            {[
                                COMPLETION_TIME.express,
                                COMPLETION_TIME.standard,
                            ].map((option) => (
                                <RadioButton
                                    id={option}
                                    name={FORM_FIELDS.completionTime}
                                    label={`${COMPLETION_TIME_LABEL[option]} (${getCompletionTime(totalDuration ?? 0, option)})`}
                                    value={option}
                                    checked={value === option}
                                    disabled={
                                        option === COMPLETION_TIME.express &&
                                        disableExpress
                                    }
                                    onChange={onChange}
                                />
                            ))}
                        </SOptionsWrapper>
                    )
                }}
            />
        </SectionWrapper>
    )
}
