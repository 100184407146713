import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button, Error } from '../../../../components'
import copy from 'copy-to-clipboard'

const SErrorMessage = styled.div`
    text-align: center;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`

const SLink = styled.a`
    color: ${({ theme: { colors } }) => colors.secondary};
    font-weight: 600;
`

const STransactionId = styled.p`
    color: ${({ theme: { colors } }) => colors.secondary};
    font-weight: 600;
`

const SError = styled(Error)`
    align-self: center;
`

export const ErrorMessage = ({
    orderId,
    orderNumber,
    text = 'Wystąpił błąd przy przetwarzaniu transakcji.',
}) => {
    const [copied, setCopied] = useState(false)

    const copyOrderId = () => {
        copy(orderId)
        setCopied(true)
    }

    const info = useMemo(() => {
        if (orderNumber)
            return 'Aby przyspieszyć proces, przy zgłoszeniu podaj numer zamówienia:'

        return 'Aby przyspieszyć proces, przy zgłoszeniu podaj ID zamówienia:'
    }, [orderNumber])

    const buttonText = useMemo(() => {
        if (orderNumber) return 'Skopiuj numer zamówienia'

        return 'Skopiuj ID zamówienia'
    }, [orderNumber])

    return (
        <SErrorMessage>
            <SError
                error={text || 'Wystąpił błąd przy przetwarzaniu transakcji.'}
                icon
            />
            <p>
                Skontaktuj się z biurem obsługi klienta:{' '}
                <SLink href="mailto:biuro@protranskrypcje.pl">
                    biuro@protranskrypcje.pl
                </SLink>
                .
            </p>
            {(orderId || orderNumber) && (
                <>
                    <p>{info}</p>
                    <STransactionId>
                        #{orderNumber || orderId}{' '}
                        {copied && <p>(Numer skopiowany!)</p>}
                    </STransactionId>
                    {<Button onClick={copyOrderId}>{buttonText}</Button>}
                </>
            )}
        </SErrorMessage>
    )
}
