import React from 'react'
import {
    CompletionTimeSelect,
    FileSelectSection,
    Summary,
    Tags,
    ClientDataSection,
    InvoiceDataSection,
    PermissionsSection,
} from './sections'
import { Layout } from '../../layout'
import { FormWrapper } from './components'
import { StenogramPrevewSection } from './sections/stenogram-preview'

export const Index = () => {
    return (
        <FormWrapper>
            <Layout
                sidebar={
                    <>
                        <Summary />
                        <Tags />
                    </>
                }
            >
                <StenogramPrevewSection />
                <FileSelectSection />
                <CompletionTimeSelect />
                <ClientDataSection />
                <InvoiceDataSection />
                <PermissionsSection />
            </Layout>
        </FormWrapper>
    )
}
