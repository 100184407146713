import React from 'react'
import { Dropzone, SectionWrapper } from '../../components'
import { FORM_FIELDS } from '../../../../constants'
import { Controller, useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

export const FileSelectSection = () => {
    const { control, trigger, getValues } = useFormContext()

    return (
        <SectionWrapper>
            <Controller
                name={FORM_FIELDS.files}
                control={control}
                render={({
                    field: { value, onChange },
                    fieldState,
                    formState,
                }) => {
                    const onDrop = (value) => {
                        const files = value.map((file) => ({
                            file,
                            tempId: uuidv4(),
                            progress: 0,
                            duration: 0,
                            analysisReady: false,
                            error: null,
                        }))

                        const uploadedFiles = getValues(FORM_FIELDS.files)

                        onChange([...files, ...uploadedFiles])

                        trigger(FORM_FIELDS.files)
                    }

                    return (
                        <Dropzone
                            value={value}
                            invalid={
                                formState.isSubmitted && fieldState.invalid
                            }
                            onDrop={onDrop}
                        />
                    )
                }}
            />
        </SectionWrapper>
    )
}
